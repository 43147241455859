import { render, staticRenderFns } from "./Mission.vue?vue&type=template&id=c03e86d4&"
import script from "./Mission.vue?vue&type=script&lang=js&"
export * from "./Mission.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SwiperCarrouselCards: require('/codebuild/output/src3813353677/src/lusio/components/SwiperCarrouselCards.vue').default})
